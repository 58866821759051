<template>
  <div class="space-y-10 smrt_stations">
    <asom-card>
      <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
        <asom-form-field label="Date" required>
          <asom-input-date-range v-model="filters.dateRange" />
        </asom-form-field>
        <asom-form-field label="Line" required>
          <asom-input-select
            :disabled="!canSelectLine"
            v-model="filters.line"
            :options="lineOptions"
          />
        </asom-form-field>
        <asom-form-field label="Station" required>
          <asom-input-select
            v-model="filters.station"
            :options="stationOptions"
          />
        </asom-form-field>
        <asom-form-field label="Officer">
          <asom-multi-select
            :objectModelValue="false"
            v-model="filters.officerIds"
            :options="officerOptions"
            placeholder="Select officer(s)"
          />
        </asom-form-field>
      </div>
      <template #footer>
        <asom-export-to-excel
          :fileName="$t('screens.manpower.PendingReplacements.pageTitle')"
          :fields="columns.fields"
          :labels="columns.labels"
          :data="columns.data"
        />
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" :loading="isLoading" @click="loadData(true)" />
      </template>
    </asom-card>
    <div class="w-full text-center" v-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <asom-client-table
      v-else
      :columns="columns.fields"
      :data="columns.data"
      :sortableColumns="['officer', 'personNo', 'line', 'dws', 'station', 'planDate', 'coverageRequirementStart', 'coverageRequirementEnd']"
    >
      <!-- <template v-slot:officer="scopedSlots">
        {{
          scopedSlots.rowData.roster ? scopedSlots.rowData.roster.officer : ""
        }}
      </template>
      <template v-slot:line="scopedSlots">
        {{
          scopedSlots.rowData.roster.line
            ? scopedSlots.rowData.roster.line.lineName
            : ""
        }}
      </template>
      <template v-slot:station="scopedSlots">
        {{
          scopedSlots.rowData.roster.station
            ? scopedSlots.rowData.roster.station.stationName
            : ""
        }}
      </template>
      <template v-slot:planDate="scopedSlots">
        {{
          scopedSlots.rowData.roster ? scopedSlots.rowData.roster.planDate : ""
        }}
      </template>
      <template v-slot:dws="scopedSlots">
        {{ scopedSlots.rowData.roster ? scopedSlots.rowData.roster.dws : "" }}
      </template> -->
      <template v-slot:header_coverageRequirementStart>Vacant Start</template>
      <template v-slot:header_coverageRequirementEnd>Vacant Till</template>
      <template v-slot:action="scopedSlots">
        <asom-button
          outline
          text="Show Suggestions"
          variant="success"
          @click="
            $router.push({
              name: 'Staff Replacement',
              params: { rosterId: scopedSlots.rowData.roster.rosterId },
            })
          "
        />
      </template>
    </asom-client-table>
  </div>
</template>

<script>
import get from "lodash.get";
import moment from 'moment';
import { mapGetters } from "vuex";
import { parseDateTime } from "@/helpers/dateTimeHelpers";
import { listUsers } from "@/services/user.service";
import { getListOfPendingReplacements } from "@/services/manpower.service";
import { setStorage, getStorage } from "@/helpers/sessionStorage";

export default {
  name: "PendingReplacements",
  data() {
    return {
      filters: {
        dateRange: {
          start: moment().toDate(),
          end: moment()
            .add(3, "months")
            .toDate(),
        },
        station: null,
        line: this.$store.getters['auth/userLineOption'],
        officerIds: [],
      },
      officerOptions: [],
      tableData: [],
      isLoading: false,
      error: null,

      searchVal: null,
      filterEcho: 0,
    };
  },
  watch: {
    "filters.line": function(newValue) {
      if(!this.filterEcho || this.filterEcho > 1){
        if (newValue) {
          this.filters.station = null;
          this.filters.officerIds = [];
        }
      }
      this.filterEcho = this.filterEcho + 1;
      this.queryOfficerList();
    },
    "filters.station": function() {
      if(!this.filterEcho || this.filterEcho > 2){
        this.filters.officerIds = [];
      }
      this.filterEcho = this.filterEcho + 1;
      this.queryOfficerList();
    },
  },
  mounted() {
    this.searchVal = getStorage("pending-replacements");
    if(this.searchVal) {
      this.filterEcho = 1;

      // UTC时间转换成对应的本地时间
      if(this.searchVal.dateRange && this.searchVal.dateRange.start)  this.searchVal.dateRange.start = moment.utc(this.searchVal.dateRange.start).toDate();
      if(this.searchVal.dateRange && this.searchVal.dateRange.end) this.searchVal.dateRange.end = moment.utc(this.searchVal.dateRange.end).toDate();
      this.filters = this.searchVal;
    }
    this.$nextTick(() => {
      this.filters.station = this.$store.getters["smrtResource/stationOptionsByLineId"](
        this.userLineId, this.userStationId
      )
      this.queryOfficerList();
      this.loadData();
    })
  },
  computed: {
    ...mapGetters({
      userLineId: "auth/userLineId",
      userStationId: "auth/userStationId",
      lineOptions: "smrtResource/lineOptions",
      userId: "auth/userId",
      canMarkUnavailability: "auth/canMarkUnavailability",
      canSelectLine: 'auth/canSelectLine',
    }),
    columns() {
      const fields = [
        "officer",
        "personNo",
        "line",
        "station",
        "planDate",
        "dws",
        "coverageRequirementStart",
        "coverageRequirementEnd",
      ];
      const labels = [
        "Officer",
        "Line",
        "Station",
        "Plan Date",
        "DWS",
        "Vacant Start",
        "Vacant Till",
      ];
      const data = [];
      this.tableData.forEach((element, i) => {
        data[i] = fields.reduce((accm, field) => {
          let d = element[field];
          if (field == "officer") d = get(element, "roster.officer", "");
          else if (field == "personNo") d = get(element, "roster.personNo", "");
          else if (field == "line") d = get(element, "roster.line.lineName", "");
          else if (field == "station") d = get(element, "roster.station.stationName", "");
          else if (field == "planDate") d = get(element, "roster.planDate", "");
          else if (field == "dws") d = get(element, "roster.dws", "");
          accm[field] = d;
          if (field == "personNo") console.log(field, 'accm======', accm);
          return accm;
        }, {});
      });
      return {
        fields,
        labels,
        data,
      };
    },
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        get(this.filters.line, "value")
      );
    },
    queryParams() {
      let params = {};
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["datefrom"] = parseDateTime(this.filters.dateRange.start);
        params["dateto"] = parseDateTime(this.filters.dateRange.end);
      }
      if (this.filters.line) {
        params["lineId"] = this.filters.line.value;
      }
      if (this.filters.station) {
        params["stationId"] = this.filters.station.value;
      }
      if (this.filters.officerIds) {
        params["officers"] = this.filters.officerIds;
      }
      return params;
    },
  },
  methods: {
    async loadData(status) {
      if(status) setStorage("pending-replacements", this.filters);
      this.isLoading = true;
      const resp = await getListOfPendingReplacements({
        skip: 0,
        take: 100,
        ...this.queryParams,
      });
      if (resp.success) {
        this.tableData = get(resp, "payload.list", []);
      } else {
        this.error = resp.payload;
        this.isLoading = false;
        this.$scrollTop();
        return;
      }
      this.isLoading = false;
    },
    resetFilters() {
      this.filters.dateRange = null;
      this.filters.station = null;
      this.filters.officerIds = [];
      this.tableData = [];
    },
    async queryOfficerList() {
      const result = await listUsers({
        skip: 0,
        take: 100,
        lineId: this.filters.line ? this.filters.line.value : null,
        stationId: this.filters.station ? this.filters.station.value : null,
      });

      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.error = null;
        this.officerOptions = get(result, "payload.list").map(
          ({ id, name, personNo }) => ({
            label: `${name} (${personNo})`,
            value: id,
          })
        );
      } else {
        this.error = result.payload;
        this.officerOptions = [];
        this.$scrollTop();
      }
    },
  },
};
</script>
